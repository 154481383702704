import React from 'react'
import { Link } from 'gatsby'
import '../components/styles/global.scss'

export default function NotFoundPage() {

  return (
    <div id='microsite'>
      <header>
        <div className='bar'>
            <div className='inner'>
              <div id='logo-menu-wrapper'>
                <img className='logo' src='/svg/exceedet-logo.svg' alt='EXCEED ET clinical trial logo'/>
              </div>
            </div>
        </div>
      </header>

      <div id='content-wrapper' className='error-404'>
        <h2>Sorry</h2>
        <p>The page you selected does not exist.</p>
        <Link to='/'>Go to Home Page &rarr;</Link>
      </div>
    </div>
  )
}

